import React from "react";
import { Helmet } from "react-helmet";
import { Img, Heading } from "../../components";

export default function DesktopOnePage() {
  return (
    <>
      <Helmet>
        <title>test</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full justify-center bg-white-A700 pb-[364px] md:flex-col md:pb-5">
        <div className="flex flex-1 flex-col items-start gap-[3px] bg-blue-100 py-[7px] md:self-stretch md:p-5">
          <Img src="images/img_back_arrow.png" alt="backarrow_one" className="h-[43px] w-[6%] object-cover" />
          <div className="mb-[5px] ml-[62px] flex w-[81%] flex-col items-start gap-[26px] md:ml-0 md:w-full">
            <Heading size="s" as="h1">
              DesignPro
            </Heading>
            <Heading as="h2">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
              industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.....
            </Heading>
          </div>
        </div>
        <Img src="images/img_ai1_1.png" alt="ai1one_one" className="h-[660px] w-[40%] object-cover md:w-full" />
      </div>
    </>
  );
}
