import React from "react";

const sizes = {
  s: "text-[40px] font-bold md:text-[38px] sm:text-4xl",
  xs: "text-[35px] font-bold md:text-[33px] sm:text-[31px]",
};

const Heading = ({ children, className = "", size = "xs", as, ...restProps }) => {
  const Component = as || "h6";

  return (
    <Component className={`text-black-900 font-rasa ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
